@import "./variables.styl"
// @import "./abstract.styl"
// @import "./mixins.styl"

/**
 * Admin
 */
.admin-controls
  position: relative

  overflow: hidden

.admin-search
  position: relative

  width: 90%

  margin: 0

  border-top-right-radius: 0
  border-bottom-left-radius: 0
  border-bottom-right-radius: 0

  float: left

.admin-limit
  position: relative

  width: 10%

  margin: 0

  border-top-left-radius: 0
  border-bottom-left-radius: 0
  border-bottom-right-radius: 0

  float: left

.admin-pagination
  position: relative

  width: 100%

  margin-top: 0

  border-top-left-radius: 0
  border-top-right-radius: 0

  background: $color_grey

  li.first > span
    border-top-left-radius: 0

  li.last > span
    border-top-right-radius: 0
    border-bottom-right-radius: 0

  &--top
    margin-bottom: -6px

    li.first > span
      border-top-left-radius: 0
      border-bottom-left-radius: 0

.admin-table
  position: relative

  margin-top: -1px
  margin-bottom: -1px

  .admin-table__header
    padding: 10px

    text-align: center

    &--green
      background: $color_flt_green

    &--yellow
      background: $color_flt_yellow

  .admin-table__item
    &--link
      cursor: pointer

      &:hover
        background: darken($color_grey, 10%)

    td > span
      margin-right: 5px
      font-size: 14px

  .admin-table__build-status
    padding: 4px

    border-radius: 4px

    color: $color_white

    &--pending
      background: $color_flt_green

    &--published
      background: $color_flt_purple

    &--unpublished
      background: $color_flt_blue

    &--denied
      background: $color_flt_red

    &--deleted
      background: $color_flt_black

  .admin-table__release-status
    padding: 5px

    border-radius: 4px

    color: $color_white

    &--public
      background: $color_flt_green

    &--private
      background: $color_flt_blue

    &--deleted
      background: $color_flt_black

  .admin-table__btn
    position: relative

    padding: 3px

    border: none

.form-release
  .form-release__btn
    position: relative

    transition: background 0.3s linear
    &:disabled
      background: $color_black

  .form-release__error
    color: $color_flt_red
    font-weight: 700

.release-notes
  textarea
    resize: vertical

  .release-notes__content-box
    width: 50%

    &--left
      float: left

    &--right
      float: right

  .release-notes__content
    &--padding
      padding: 0 6px

.pagination-link
  cursor: pointer
