@import "./variables.styl"
// @import "./abstract.styl"
// @import "./mixins.styl"

/**
 * Header
 */
.header
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 48px
  
  background: $color_black
  
  overflow: hidden

  z-index: $z_nav_box
  
  .header__logo
    display: block
    position: absolute
    
    top: 0
    left: 0
    
    width: $logo_w
    height: $logo_h
    
    padding: ((48px - $logo_h) / 2)
    margin-left: $wrap_pad
    
    background: none
    background-image: url($STATIC_ADDR + "/public/images/logo.png")
    background-size: auto @height
    background-position: center
    background-repeat: no-repeat
    
    box-sizing: content-box
  
    @media $media_q_laptop
      margin-left: 96px
      
    @media $media_q_ipad
      margin-left: 64px
      
    @media $media_q_iphone
      margin-left: 32px
