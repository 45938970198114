/**
 * Errors
 */
.error
  padding: 10px
  
  text-align: center

  .error__code
    @extend $text-shadow-small

    font-size: 10em
    font-weight: 700
  
  .error__message
    @extend $text-shadow-small
    
    font-size: 2em
