@import "./variables.styl"
// @import "./abstract.styl"
// @import "./mixins.styl"

/**
 * General
 */
body
  min-width: $content_min_w

  background: darken($color_main_bg, 10%)
  
  color: $color_main_color
  
  font-family: $font_main

.wrap
  position: relative
  
  width: 100%
  max-width: $wrap_max_w
  
  margin: auto

  box-shadow: 0 0 10px rgba($color_black, 0.2)
  
.page-content
  padding: 48px $wrap_pad
  
  background: $color_light_grey

  overflow: hidden
  
  @media $media_q_laptop
    padding: 48px 96px
    
  @media $media_q_ipad
    padding: 48px 64px
    
  @media $media_q_iphone
    padding: 48px 32px
  
  &--white
    background: $color_white

.clear
  clear: both
