@import "./variables.styl"
// @import "./abstract.styl"
// @import "./mixins.styl"

/**
 * Download
 */
.download-box
  padding: 0 48px
  padding-top: 48px
  
.download
  padding: 20px
  
  text-align: center
  
  .download__title
    margin-bottom: 50px
    
    font-size: $font_size * 2

  .download__description
    margin-bottom: 40px
    
    font-size: $font_size * 1.2
  
  .download__answer
    font-size: $font_size

  .download__link
    margin-left: 7px
    
.line
  height: 1px
  border-bottom: solid 1px darken($color_light_grey, 20%)

/**
 * Content
 */
.content
  display: flex
  justify-content: space-between

.step
  max-width: (100% / 3)
  
  margin: 50px 10px

  .step__title
    font-size: $font_size * 2
    font-weight: 500
  
    color: $color_flt_orange
      
  .step__description
    min-height: 90px
    
    padding-bottom: 10px
    
    font-size: ($font_size * 1.1)
      
  .step__image
    margin: 0 auto
    
    background: none
    background-size: contain
    background-position: center
    background-repeat: no-repeat

    &--0
      background-image: url($STATIC_ADDR + "/public/images/step-1.png")

      width: 340px
      height: 160px

    &--1
      background-image: url($STATIC_ADDR + "/public/images/step-2.png")
      
      width: 356px
      height: 262px

    &--2
      background-image: url($STATIC_ADDR + "/public/images/step-3.png")
      
      width: 356px
      height: 463px
