@import "./variables.styl"
// @import "./abstract.styl"
// @import "./mixins.styl"

/**
 * Footer
 */
.footer
  max-width: $wrap_max_w
  height: 150px
  
  padding-top: 90px
  
  background: $color_black
  
.footer-copyright
  padding: 0 200px
  
  font-size: 12px
  
  @media $media_q_ipad
    padding: 0
    
    text-align: center
    
  .footer-copyright__text
    display: inline-block
    
    margin: 8px
    
    color: darken($color_light_grey, 30%)
  
  .footer-copyright__link
    display: inline-block
    
    margin: 8px
    
    color: darken($color_light_grey, 30%)
    
    text-decoration: none
    
    transition: color 0.2s linear
    
    &:hover
      color: darken($color_light_grey, 45%)
    &:active
      color: darken($color_light_grey, 60%)
