@import "./variables.styl"
// @import "./abstract.styl"
// @import "./mixins.styl"

/**
 * Releases
 */
.releases
  // noop

.release
  padding: 10px
  
  .release__version
    font-size: round($font_size * 1.5)
  
  .release__notes
    //  nope
