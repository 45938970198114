@import "./variables.styl"

/**
 * Abstract
 */
$block-shadow
  box-shadow: 0 2px 4px rgba($color_black, 0.3)

$block-shadow-small
  box-shadow: 0 2px 2px rgba($color_black, 0.3)

$block-shadow-google-map
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.298039)

$block-shadow-item
  box-shadow: 0 4px 8px 0 rgba(50, 86, 123, 0.16)

$text-shadow
  text-shadow: 0 2px 4px rgba($color_black, 0.3)

$text-shadow-small
  text-shadow: 0 2px 2px rgba($color_black, 0.3)

$item-border
  border: 1px solid $color_border

$item-block
  @extend $block-shadow-item, $item-border

  border-radius: 4px
  
  background: $color_content
  
  padding: 42px
