@import "./vendor/bootstrap.css";
.error .error__code,
.error .error__message {
  text-shadow: 0 2px 2px rgba(26,26,26,0.3);
}
/** Icon */
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,100,300,700&subset=latin,greek,greek-ext,vietnamese,cyrillic-ext,latin-ext,cyrillic");
body {
  min-width: 1440px;
  background: #e6e6e6;
  color: #4d4d4d;
  font-family: "Roboto";
}
.wrap {
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: auto;
  box-shadow: 0 0 10px rgba(26,26,26,0.2);
}
.page-content {
  padding: 48px 192px;
  background: #f5f5f5;
  overflow: hidden;
}
@media only screen and (max-width: 1144px) {
  .page-content {
    padding: 48px 96px;
  }
}
@media only screen and (max-width: 800px) {
  .page-content {
    padding: 48px 64px;
  }
}
@media only screen and (max-width: 528px) {
  .page-content {
    padding: 48px 32px;
  }
}
.page-content--white {
  background: #fff;
}
.clear {
  clear: both;
}
.error {
  padding: 10px;
  text-align: center;
}
.error .error__code {
  font-size: 10em;
  font-weight: 700;
}
.error .error__message {
  font-size: 2em;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 48px;
  background: #1a1a1a;
  overflow: hidden;
  z-index: 9000;
}
.header .header__logo {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  height: 32px;
  padding: 8px;
  margin-left: 192px;
  background: none;
  background-image: url("/public/images/logo.png");
  background-size: auto 32px;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: content-box;
}
@media only screen and (max-width: 1144px) {
  .header .header__logo {
    margin-left: 96px;
  }
}
@media only screen and (max-width: 800px) {
  .header .header__logo {
    margin-left: 64px;
  }
}
@media only screen and (max-width: 528px) {
  .header .header__logo {
    margin-left: 32px;
  }
}
.download-box {
  padding: 0 48px;
  padding-top: 48px;
}
.download {
  padding: 20px;
  text-align: center;
}
.download .download__title {
  margin-bottom: 50px;
  font-size: 32px;
}
.download .download__description {
  margin-bottom: 40px;
  font-size: 19.2px;
}
.download .download__answer {
  font-size: 16px;
}
.download .download__link {
  margin-left: 7px;
}
.line {
  height: 1px;
  border-bottom: solid 1px #c4c4c4;
}
.content {
  display: flex;
  justify-content: space-between;
}
.step {
  max-width: 33.333333333333336%;
  margin: 50px 10px;
}
.step .step__title {
  font-size: 32px;
  font-weight: 500;
  color: #f58923;
}
.step .step__description {
  min-height: 90px;
  padding-bottom: 10px;
  font-size: 17.6px;
}
.step .step__image {
  margin: 0 auto;
  background: none;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.step .step__image--0 {
  background-image: url("/public/images/step-1.png");
  width: 340px;
  height: 160px;
}
.step .step__image--1 {
  background-image: url("/public/images/step-2.png");
  width: 356px;
  height: 262px;
}
.step .step__image--2 {
  background-image: url("/public/images/step-3.png");
  width: 356px;
  height: 463px;
}
.release {
  padding: 10px;
}
.release .release__version {
  font-size: 24px;
}
.admin-controls {
  position: relative;
  overflow: hidden;
}
.admin-search {
  position: relative;
  width: 90%;
  margin: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  float: left;
}
.admin-limit {
  position: relative;
  width: 10%;
  margin: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  float: left;
}
.admin-pagination {
  position: relative;
  width: 100%;
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background: #e7e7e7;
}
.admin-pagination li.first > span {
  border-top-left-radius: 0;
}
.admin-pagination li.last > span {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.admin-pagination--top {
  margin-bottom: -6px;
}
.admin-pagination--top li.first > span {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.admin-table {
  position: relative;
  margin-top: -1px;
  margin-bottom: -1px;
}
.admin-table .admin-table__header {
  padding: 10px;
  text-align: center;
}
.admin-table .admin-table__header--green {
  background: #8cc152;
}
.admin-table .admin-table__header--yellow {
  background: #f6bb42;
}
.admin-table .admin-table__item--link {
  cursor: pointer;
}
.admin-table .admin-table__item--link:hover {
  background: #d0d0d0;
}
.admin-table .admin-table__item td > span {
  margin-right: 5px;
  font-size: 14px;
}
.admin-table .admin-table__build-status {
  padding: 4px;
  border-radius: 4px;
  color: #fff;
}
.admin-table .admin-table__build-status--pending {
  background: #8cc152;
}
.admin-table .admin-table__build-status--published {
  background: #967adc;
}
.admin-table .admin-table__build-status--unpublished {
  background: #4a89dc;
}
.admin-table .admin-table__build-status--denied {
  background: #da4453;
}
.admin-table .admin-table__build-status--deleted {
  background: #434a54;
}
.admin-table .admin-table__release-status {
  padding: 5px;
  border-radius: 4px;
  color: #fff;
}
.admin-table .admin-table__release-status--public {
  background: #8cc152;
}
.admin-table .admin-table__release-status--private {
  background: #4a89dc;
}
.admin-table .admin-table__release-status--deleted {
  background: #434a54;
}
.admin-table .admin-table__btn {
  position: relative;
  padding: 3px;
  border: none;
}
.form-release .form-release__btn {
  position: relative;
  transition: background 0.3s linear;
}
.form-release .form-release__btn:disabled {
  background: #1a1a1a;
}
.form-release .form-release__error {
  color: #da4453;
  font-weight: 700;
}
.release-notes textarea {
  resize: vertical;
}
.release-notes .release-notes__content-box {
  width: 50%;
}
.release-notes .release-notes__content-box--left {
  float: left;
}
.release-notes .release-notes__content-box--right {
  float: right;
}
.release-notes .release-notes__content--padding {
  padding: 0 6px;
}
.pagination-link {
  cursor: pointer;
}
.footer {
  max-width: 1440px;
  height: 150px;
  padding-top: 90px;
  background: #1a1a1a;
}
.footer-copyright {
  padding: 0 200px;
  font-size: 12px;
}
@media only screen and (max-width: 800px) {
  .footer-copyright {
    padding: 0;
    text-align: center;
  }
}
.footer-copyright .footer-copyright__text {
  display: inline-block;
  margin: 8px;
  color: #ababab;
}
.footer-copyright .footer-copyright__link {
  display: inline-block;
  margin: 8px;
  color: #ababab;
  text-decoration: none;
  transition: color 0.2s linear;
}
.footer-copyright .footer-copyright__link:hover {
  color: #878787;
}
.footer-copyright .footer-copyright__link:active {
  color: #626262;
}
